
<template>
  <v-app>
    <v-main>

    <router-view/>
   
    </v-main>
  </v-app>
</template>

<script>
const App = {
    data: function() {
        return {
            name: 'App'
        }
    }
}
export default App
</script>

