<template>
  <div>
    <div v-if="visibledata === 1">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="7">
            <v-row>
              <h4 class="  pa-1 mb-2 mt-2">Datos Personales</h4>
            </v-row>
            <v-row>
              <v-col cols="12" class="pa-1">
                <v-text-field v-model="payer.dni" label="Número de DNI *" :rules="dniRule" type="number"
                  hint="solo números sin punto" required persistent-hint hide-details="auto" filled
                  background-color="white" dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-text-field v-model="payer.pnombre" label="Primer Nombre *" :rules="stringRequerido" required
                  persistent-hint filled background-color="white" dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-text-field v-model="payer.snombre" label="Segundo Nombre *" :rules="stringRequerido" required
                  persistent-hint filled background-color="white" dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-text-field v-model="payer.papellido" label="Primer Apellido *" :rules="stringRequerido" required
                  persistent-hint filled background-color="white" dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-text-field v-model="payer.sapellido" label="Segundo Apellido *" :rules="stringRequerido" required
                  persistent-hint filled background-color="white" dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-text-field v-model="payer.email" label="Correo *" :rules="emailRules" required persistent-hint filled
                  background-color="white" dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-text-field v-model="payer.phone" label="Teléfono *" type="number" :rules="phoneRules" required
                  persistent-hint prefix="+51" filled background-color="white" dense></v-text-field>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" persistent width="290px" dense>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="computedDateFormatted" label="Fecha de  Nacimiento" readonly v-bind="attrs"
                      v-on="on" persistent-hint :rules="[isAdult]" filled background-color="white" dense></v-text-field>
                  </template>
                  <v-date-picker v-model="date" locale="clt-cl" :first-day-of-week="1" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                      Cancel
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.dialog.save(date)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-select v-model="payer.genero" :items="genero" label="Género" :rules="datoRequerido" outline
                  background-color="white" filled dense></v-select>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-select v-model="payer.idDepartamento" :items="departamentos" item-value="id_ubigeo"
                  :rules="datoRequerido" item-text="nombre_ubigeo" required filled label="Seleccione un Departamento"
                  @change="changeDeptos" background-color="white" dense></v-select>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-select v-model="payer.idProvincia" :items="provincias" item-value="id_ubigeo" item-text="nombre_ubigeo"
                  :rules="datoRequerido" required label="Seleccione una Provincia" @change="changeProvincias"
                  background-color="white" filled dense></v-select>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-select v-model="payer.idDistrito" :items="distritos" item-value="id_ubigeo" item-text="nombre_ubigeo"
                  :rules="datoRequerido" required label="Seleccione un Distrito" filled background-color="white"
                  dense></v-select>
              </v-col>
              <v-col cols="12" md="6" class="pa-1">
                <v-text-field v-model="payer.direccion" label="Dirección" persistent-hint background-color="white" filled
                  :rules="datoRequerido" dense></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5">
            <v-col cols="12" class="pa-1">
              <h4>Documentación</h4>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-file-input accept=".pdf" chips counter show-size label="Certificado de título" required persistent-hint
                background-color="white" filled dense :rules="datoRequerido"></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-file-input accept=".pdf" chips counter show-size label="Copia del DNI" required persistent-hint
                background-color="white" filled dense :rules="datoRequerido"></v-file-input>
              <p class="subtitle-2 pl-3 pt-0">* Debe subir los documentos en formato pdf</p>
            </v-col>
            <v-col cols="12" class="pa-1">
              <h4 class="mb-3">Resumen de tu Pedido</h4>
              <v-card class="mx-auto" outlined>
                <v-card-subtitle class="text-subtitle-1">{{ formdata.name }}</v-card-subtitle>
                <v-divider class="mx-4"></v-divider>
                <v-card-title>
                  <v-row><v-col cols="7" class="text-h5">Total a Pagar :</v-col> <v-col cols="5"
                      class="text-right text-h5 ">
                      S/{{ formdata.price }}</v-col></v-row>
                </v-card-title>
                <v-divider class="mx-4"></v-divider>
                <v-card-text class="pt-1">
                  <v-checkbox :rules="datoRequerido">
                    <template v-slot:label>
                      <div class="body-2">
                        Acepto
                        <a target="_blank" href="https://vuetifyjs.com" @click.stop>
                          Terminos y condiciones
                        </a>
                        y
                        <a target="_blank" href="https://vuetifyjs.com" @click.stop>
                          Reglamento academico
                        </a>
                      </div>
                    </template>
                  </v-checkbox>
                </v-card-text>
                <v-card-actions>
                  <v-btn depressed color="error" x-large block @click="validate">
                    Pagar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-col>
        </v-row>
      </v-form>
    </div>
    <div v-else-if="visibledata === 2">
      <h2>No se encontro información del programa</h2>
    </div>
    <div v-else-if="visibledata === 0" >
        <v-sheet elevation="6"  max-width="700" width="100%" class="pa-8 text-center mx-auto ">
          <v-icon color="error" size="90">mdi-alert</v-icon>
          <h2 class="text-h5 mb-6">Falta identificador del programa</h2>
          <p class="mb-4 text--secondary text-body-2">Asegúrate de proporcionar el identificador del programa correspondiente para acceder a la información adecuada</p>
          <v-divider class="mb-4"></v-divider>
        </v-sheet>
        
    
    </div>  
    <div>
      <v-dialog v-model="dialog" persistent max-width="500">
        <v-container style="height: 200px; background: white;">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-subtitle-1 text-center" cols="12">
              Procesando información...
            </v-col>
            <v-col cols="6">
              <v-progress-linear color="red darken-2" indeterminate rounded height="8"></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
      </v-dialog>
    </div>
  </div>
</template>
<script>

/* eslint-disable */
import departamentos from '../departamentos.json'
import provincias from '../provincias.json'
import distritos from '../distritos.json'
import axios from 'axios'



export default {
  name: 'FormInscripcion',
  data: () => ({
    visibledata: 0, //0 - cargando.  1 - cargar datos del programa.  2 - programa no encontrado  
    valid: true,
    departamentos: departamentos,
    provincias: [{ "id_ubigeo": 0, "nombre_ubigeo": "Debe seleccionar un Departamento" }],
    distritos: [{ "id_ubigeo": 0, "nombre_ubigeo": "Debe seleccionar una Provincia" }],
    payer: {
      id: null,
      dni: '',
      pnombre: '',
      snombre: '',
      papellido: '',
      sapellido: '',
      email: '',
      phone: '',
      idDepartamento: '',
      idProvincia: '',
      idDistrito: '',
      direccion: '',
      fnacimiento: '',
      genero: '',
      certificatedoc: '',
      dnidoc: ''
    },
    date: null,
    modal: false,
    dateFormatted: null,
    genero: ['Masculino', 'Femenino'],
    formdata: {
      id: '',
      name: '',
      codeProgram: '',
      currency: '',
      country: '',
      price: '',
      guid: ''
    },
    guid: '',
    dialog: false,

    //rules
    dniRule: [
      v => !!v || 'El campo es requerido',
      v => (v && v.length == 8) || 'Formato de DNI incorrecto'
    ],
    stringRequerido: [
      v => !!v || 'El campo es requerido',
      v => (v && v.length >= 3) || 'El campo debe tener más de 3 caracteres'
    ],
    emailRules: [
      v => !!v || 'El correo es requerido',
      v => (v && /.+@.+\..+/.test(v)) || 'El correo no es valido',
    ],
    phoneRules: [
      v => !!v || 'El campo es requerido',
      v => (v && v.length == 9) || 'Formato de Teléfono incorrecto'
    ],
    datoRequerido: [v => !!v || 'El campo es requerido']
  }),

  mounted() {
    this.guid = this.$route.query.gd;
    if (!this.guid) {
      this.visibledata = 0
    } else {
      this.visibledata = true
      this.getDataForm()
    }
  },

  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.dialog = false;
        this.createPayer()
      }
    },

    isAdult(value) {

      if (value != null && value.length > 0) {
        let dia = value.substring(0, 2);
        let mes = value.substring(3, 5);
        let anio = value.substring(6, 11);

        const hoy = new Date();
        const fechaNacimiento = new Date(`${anio}/${mes}/${dia}`)

        if (hoy.getTime() < fechaNacimiento.getTime())
          return 'Debe seleccionar una fecha correcta';

        const edadmilisegundos = (hoy.getTime() - fechaNacimiento.getTime());
        const edad = (((edadmilisegundos / 1000) / 60) / 1440) / 365;

        if (edad < 18) return 'La edad mínima es 18 años';
        else return true;

      } else {
        return 'Debe seleccionar una fecha';
      }
    },

    changeDeptos(selectDpto) {
      this.loadProvincia(selectDpto)
    },

    changeProvincias(selectProv) {
      this.loadDistrito(selectProv);
    },

    loadProvincia(idDpto) {
      this.provincias = [];
      this.distritos = [{ "id_ubigeo": 0, "nombre_ubigeo": "Debe seleccionar una Provincia" }];
      this.provincias = provincias.filter((pr) => pr.id_padre_ubigeo === idDpto);
    },

    loadDistrito(idProvincia) {
      this.distritos = [];
      this.distritos = distritos.filter((ds) => ds.id_padre_ubigeo === idProvincia)
    },

    formatDate(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    parseDate(date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    getDataForm() {
      axios.get(process.env.VUE_APP_API_BASE_FORMS + this.guid)
        .then((response) => {
          if (response.data.id) {
            this.formdata.name = response.data.name,
              this.formdata.currency = response.data.currency,
              this.formdata.country = response.data.country,
              this.formdata.price = response.data.price,
              this.formdata.codeProgram = response.data.codeProgram,
              this.formdata.id = response.data.id,
              this.formdata.guid = response.data.guid
            this.visibledata = 1
          } else {
            this.visibledata = 2
          }
        });
    },

    createPayer() {
      this.payer.fnacimiento = this.date;
      axios.post(process.env.VUE_APP_API_BASE_FORMS + 'payer', this.payer)
        .then((response) => {
          if (response.status == 200) {
            this.payer.id = response.data.id;
            this.payer.guid = response.data.guid;
            const body = {
              payer: this.payer,
              program: this.formdata
            }
            axios.post(process.env.VUE_APP_API_BASE_FORMS + 'urlpay', body).then((resp) => {
              if (response.status == 200) {
                console.log(resp)
                window.location.href = resp.data.dlocal;
              }
            })
          }
        });
    }
  },

  computed: {
    computedDateFormatted() {
      this.payer.fnacimiento = this.formatDate(this.date);
      return this.formatDate(this.date)
    }
  },
}



</script>
        