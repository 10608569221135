<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="12" class="text-center">
                <v-icon size="60" color="blue darken-2">
                    mdi-check-circle-outline
                </v-icon>
                <h3 class="mt-5 mb-5">¡Gracias por tu Compra!</h3>
                <p class="text-left">
                    Felicidades! Has dado un paso importante hacia el enriquecimiento de
                    tu conocimiento y desarrollo personal. Estamos emocionados de tenerte
                    como parte de nuestra comunidad educativa. A continuación, encontrarás
                    información importante y algunos pasos adicionales para que aproveches
                    al máximo tu experiencia de aprendizaje.
                </p>

                <div class="text-left">
                    <h4 class="mb-2">Detalle de la Compra</h4>
                    <v-divider class="mb-5"></v-divider>
                    <v-container>
                        <p label outlined class="subtitle-1">
                            <strong> Identificador de Transacción: </strong>{{ guid }}
                        </p>
                        <p label outlined class="subtitle-1"> <strong> Fecha: </strong>{{ transaction.createdAt }}
                        </p>
                        <p label outlined class="subtitle-1">
                            <strong> Nombre del programa: </strong>{{ transaction.programName }}
                        </p>
                        <p label outlined class="subtitle-1">
                            <strong> Tipo de programa : </strong>{{ transaction.TypeName }}
                        </p>
                        <p label outlined class="subtitle-1">
                            <strong> Precio : </strong>{{ transaction.price }}
                        </p>
                    </v-container>
                    <v-divider></v-divider>
                    <p class="text-left mt-3">
                        Nos pondremos en contacto muy pronto, si tienes dudas o consultas
                        puedes escribirnos a : <strong> 123123@gxxxxx.cl </strong>
                    </p>
                </div>
            </v-col>
        </v-row>
    </v-container>

</template>

<script>
import axios from "axios";

/* eslint-disable */

export default {
    name: "SuccessCheckOut",
    data: () => ({
        guid: "",
        visible: false,
        transaction: {
            createdAt: "",
            programName: "",
            description: "",
            TypeName: "",
            price: "",
        },
    }),

    mounted() {
        //obtengo el parametro de la url 
        this.guid = this.$route.query.gd;
        if (this.guid) {
            this.getEnroll(this.guid);
        }
    },


    methods: {
        getEnroll(guid) {
            console.log(guid);
            axios

                .get(`${process.env.VUE_APP_API_BASE_FORMS}success/${this.guid}`)
                .then((response) => {
                    console.log(response.status);
                    if (response.data.createdAt) {
                        (this.transaction.createdAt = response.data.createdAt),
                            (this.transaction.programName = response.data.program.name),
                            (this.transaction.description = response.data.program.name),
                            (this.transaction.TypeName =
                                response.data.program.program_type.name),
                            (this.transaction.price = response.data.price);
                    } else {

                    }
                }).catch(function (error) {
                    console.log(JSON.stringify(error.response.status))
                });
        },
    },
};
</script>
