<template lang="es">
    
    <div class="formInscripcion">
        <v-container>
    <FormInscripcion/>
</v-container>
    </div>
</template>


<script>
import FormInscripcion from '@/components/FormInscripcion.vue'



export default {
    name:'InscripcionView',
    components:{
        FormInscripcion,
    },
  
}
</script>
<style  scoped>
.formInscripcion{
  background: #f4f5fb;
  width: 100%;
}
</style>


