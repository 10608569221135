<template lang="es">
    
    <div >
        <v-container>
    <SuccessCheckOut/>
</v-container>
    </div>
</template>


<script>
import SuccessCheckOut from '@/components/SuccessCheckOut.vue'
export default {
    name:'SuccessView',
    components:{
        SuccessCheckOut,
    },
  
}
</script>
<style  scoped>